<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>高校就业</el-breadcrumb-item>
                <el-breadcrumb-item>报到证申请</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="yybox">
            <div class="yycont"  v-if="!showresult && !ishave">
                <el-form :inline="true" :model="form" label-width="100px" class="demo-form-inline">
                    <el-form-item label="预约日期：">
                        <el-date-picker
                        v-model="form.preReportTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                        @change="changeDate"
                        placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="时间段：">
                        <el-select v-model="form.preForwardTimeQuantum" placeholder="时间段">
                        <el-option v-for="item in opetion" :label="item.desc" :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    
                    <el-form-item label="手机号：">
                        <el-input v-model="form.mobile" @blur="checkPhone" style="width:218px;"></el-input>
                    </el-form-item>
                </el-form>
                <div>
                    上传附件<span style="font-size:12px; color:#888;" >（格式png/jpg，每张图片大小2M以内）</span>
                </div>
                <div style="margin-top:15px;">
                    <Uploadimg :yuanlist="yuanlist" @changeurl="getImgurl"></Uploadimg>
                </div>
                <div class="yytext">
                    <div class="yytexttitle">一、高校毕业生办理报到业务所需材料：</div>
                    <div class="yytextabout">
                        <p>（1）报到证原件；</p>
                        <p>（2）身份证（原件、复印件）；</p>
                        <p>（3）毕业证（原件、复印件）；</p>
                        <p>（4）学信网《教育部学历证书电子注册备案表》（学历在线验证报告）；</p>
                        <p>（5）受委托办理的，需携委托函及被委托人身份证（原件、复印件）；</p>
                    </div>
                    <div class="yytexttitle">二、注意事项：</div>
                    <div class="yytextabout">
                        <p>请办事人员全程佩戴口罩，现场取号有序办理业务，配合工作人员做好疫情防控有关工作。</p>
                    </div>
                    <div class="yytexttitle">三、办理地址及联系方式：</div>
                    <div class="yytextabout">
                        <p>办理地址：广信区人力资源和社会保障局一楼大厅（广信区信州区广平街16号）</p>
                        <p>联系方式：0793-7326170</p>
                    </div>
                    <div class="yybtnbox">
                        <span @click="confirm">立即预约</span>
                    </div>
                </div>
            </div>
            <div class="result" v-if="showresult">
                <el-result icon="success" title="预约成功" subTitle="您的报到证已预约成功，记得赴约哦！">
                    <template slot="extra">
                        <el-button type="primary" size="medium" @click="$router.push('/school/job')">返回</el-button>
                    </template>
                </el-result>
            </div>
            <div class="result" v-if="!showresult && ishave">
                <el-result icon="success" title="您已预约过了！" :subTitle="'预约日期'+ bdz.preReportTime">
                    <template slot="extra">
                        <el-button type="primary" size="medium" @click="$router.push('/school/job')">返回</el-button>
                    </template>
                </el-result>
            </div>
        </div>
    </div>
</template>
<script>
import userRequest from "@/api/user";
import Uploadimg from '@/components/upload/uploadImg.vue'
export default{
    components:{
        Uploadimg,
    },
    data(){
        return{
            bdz:null,
            ishave:false,
            showresult:false,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            yuanlist:[],
            opetion:[],
            phoneok:false,
            form:{
                preReportTime:null,//预约日期
                preForwardTimeQuantum:null,//预约时间段
                mobile:null,
                attachment:null,//附件地址

            }
        }
    },
    created(){
        let roleId=localStorage.getItem("roleId")
        console.log(roleId)
        if(roleId!=99 || !roleId){
            this.$message.warning("请先登录！")
            setTimeout(()=>{
                this.$router.push("/newUserLogin")
            },1000)
            
        }
        this.getStatus()
    },
    methods:{
        getStatus(){//判断用户是否已经有预约
            userRequest.getLaseByToken().then(res=>{
                console.log(res)
                if(res.code==200){
                    if(res.data){
                        this.ishave=true
                        this.bdz=res.data
                    }else{
                        this.ishave=false
                    }
                }
            })
        },
        confirm(){
            if(!this.form.preReportTime){
                alert("请选择预约日期")
                return
            }
            if(!this.form.preForwardTimeQuantum){
                alert("请选择预约时段")
                return
            }
            if(!this.form.mobile){
                alert("请输入手机")
                return
            }
            if(!this.form.attachment){
                alert("请上传必要的附件！")
                return
            }

            userRequest.forward(this.form).then(res=>{
                console.log(res)
                if(res.code==200){
                    this.showresult=true
                    // this.$message({
                    //     type: 'success',
                    //     message: '预约成功!'
                    // });

                }else{
                    this.$message({
                        type: 'warning',
                        message: res.message
                    });
                }
            })




        },
        getImgurl(data){
            console.log(data)
            this.form.attachment=data.join(",")
        },
        checkPhone(){
            var str=this.form.mobile;
            var myreg=/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
            if (!myreg.test(str)) {
                this.phoneok=false
                alert("请输入正确的手机号！")
            } else {
                this.phoneok=true
            }
        },
        changeDate(){
            this.getTime()
        },
        getTime(){
            userRequest.forwardtime({date:this.form.preReportTime}).then(res=>{
                if(res.code==200){
                    let newData = []
                    res.data.forEach((item) => {
                        if (item.available) {
                            newData.push(item)
                        }
                    })
                    console.log(newData);
                    this.opetion = newData
                }
            })
        }
    }
}
</script>

<style scoped>
.wz{ width: 1200px; margin: 0 auto; padding: 16px 0;}
.yybox{ background: #fff; width: 1200px; margin: 20px auto 40px auto; padding: 30px;}
.yycont{ width: 830px; background: #FAFAFA; margin: 0 auto; padding: 30px;}
.yytext{ margin-top: 18px;}
.yytexttitle{ font-size: 15px; font-weight: bold; height: 20px; margin-bottom: 6px;}
.yytextabout p{ font-size: 14px; color:#999999; margin:5px 0 18px 0;}
.yybtnbox{ text-align: center; margin-top: 18px;}
.yybtnbox span{width: 390px;
display: inline-block;
            height: 48px;
            color: #ffffff;
            cursor: pointer;
            line-height: 48px;
            font-size: 18px;
            background: rgba(23, 135, 251);
            opacity: 1;
            border-radius: 2px;}


</style>


